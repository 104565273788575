:root {
  --systemRed:rgba(244,49,48,1);
  --systemRedDark:rgba(244,49,48,0.146);
  --systemOrange:rgba(255,149,0,1);
  --systemYellow:rgba(255,204,0,1);
  --systemGreen:rgba(52,199,89,1);
  --systemGreenDark:rgba(52,199,89,0.146);
  --brandGold: rgba(227,216,118,1);
  --brandBlue: rgba(18,181,234,1);
}

body {
  font-family: arial, sans-serif;
  background-color: black;
}
input[type=range] {
  -webkit-appearance: none;
  background-color: black;
  width: 100%;
  margin: 13.8px 0;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: var(--brandGold);
  border-radius: 1.3px;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: white;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -14px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: var(--brandGold);
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: var(--brandGold);
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: white;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
}
input[type=range]::-ms-fill-lower {
  background: black;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-fill-upper {
  background: black;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: white;
  cursor: pointer;
  height: 8.4px;
}
input[type=range]:focus::-ms-fill-lower {
  background: var(--brandGold);
}
input[type=range]:focus::-ms-fill-upper {
  background: var(--brandGold);
}


.App {
    width: 500px;
    margin: 20px auto;
    border: white 2px solid;
    padding: 0;
    border-radius: 6px;
    background-color: black;
}

.header {
    color: black;
    background-color: var(--brandGold);
    padding: 13px 16px 8px;
}

.header h1 {
  font-size: 18px;
}

.header p {
  color: #abe4ea;
  font-size: 14px;
  padding-bottom: 8px;
  margin: 0;
}

form {
  padding: 26px 18px;
}

.output {
    padding: 10px 18px;
    border-top: 1px solid #ebebeb;
}

.output h3 {
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 10px;
    color: var(--brandGold);
}

.danger a {
    color: #4e4b4b;
    margin-left: 9px;
    font-weight: 300;
    font-size: 15px;
}

form label {
  color: white;
}

form span {
    font-weight: bold;
    margin-left: 5px;
}